




































































































































































































import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { DataTable, SurveyForm } from '@/components/admin';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import SurveyFormMixin from '@/mixins/SurveyFormMixin';
import SurveyMixin from '@/mixins/SurveyMixin';

@Component({
  name: 'Client',
  components: {
    DataTable,
    SurveyForm,
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('survey', ['getList', 'getCount']),
  },
})
export default class Client extends Mixins(SurveyFormMixin, SurveyMixin) {
  excludeFields = 'client.id';
  client = {};
  selected: any = {};
  fields = [
    {
      key: 'projectCode',
      sortable: true,
      thClass: 'width-100',
      tdClass: 'text-uppercase',
    },
    {
      key: 'teamName',
      sortable: true,
      thClass: 'width-95',
    },
    {
      key: 'template.name',
      sortKey: 'name',
      sortable: true,
      label: 'Survey Type',
      thClass: 'survey-type',
    },
    {
      key: 'status',
      sortable: true,
      thClass: 'width-135',
    },
    {
      key: 'createdAt',
      label: 'Created',
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'startDate',
      sortable: true,
      thClass: 'width-85',
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'endDate',
      sortable: true,
      formatter(val) {
        return moment(val).format(process.env.VUE_APP_DATE_FORMAT);
      },
    },
    {
      key: 'participantLimit',
      label: 'Participants',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'totalParticipants',
      label: 'Completions',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'percentComplete',
      label: 'Complete',
      sortable: true,
      thClass: 'width-95',
      formatter(val) {
        return `${val}%`;
      },
    },
    {
      key: 'daysLeft',
      sortable: true,
      formatter(val) {
        return _.isInteger(val) ? val : '-';
      },
    },
    {
      key: 'averageTime',
      label: 'Avg Time',
      sortable: true,
      formatter(val) {
        const minutes = (val / 60)
          .toFixed(1)
          .replace('0.0', '')
          .replace('.0', '');
        return parseInt(minutes) ? `${minutes} mins` : '-';
        // return val ? moment.duration(val, 'seconds').humanize() : '-'
      },
    },
  ];

  completionFields = [
    {
      key: 'fullName',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Submitted',
      formatter(val) {
        return val
          ? moment(val).format(process.env.VUE_APP_DATETIME_FORMAT)
          : null;
      },
      sortable: true,
    },
  ];

  // HOOKS

  created() {
    const id = this.$route.params.id;
    return this.$store.dispatch('client/get', { id }).then((res) => {
      this.client = res;
    });
  }

  // METHODS

  fetchData(params: any = {}) {
    params.clientIds = this.$route.params.id;
    this.$store.dispatch('survey/list', { params });
    this.$store.dispatch('survey/count', { params });
  }

  addSurvey() {
    this.selected = Object.assign({}, { client: this.client });
    this.$refs.addModal.show();
  }

  editSurvey(survey) {
    this.selected = Object.assign({}, survey);
    this.$refs.editModal.show();
  }

  duplicateSurvey(survey) {
    this.selected = Object.assign({}, survey);
    this.$refs.duplicateModal.show();
  }

  deleteSurvey(survey) {
    this.selected = Object.assign({}, survey);
    this.$refs.deleteModal.show();
  }

  onCancel() {
    this.selected = null;
    this.$refs.addModal.hide();
    this.$refs.editModal.hide();
  }
}
